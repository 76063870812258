<template>
<div class="projects dirr my-md-8">
    <div class="desc" v-if="projects_skeleton">
        <v-skeleton-loader  class="mx-auto" width="350" min-width="350" max-width="350" type="paragraph, paragraph, paragraph, sentences, paragraph, sentences, actions"></v-skeleton-loader>
    </div>
    <div class="desc" v-else>
        <div class="title">
            <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
            <a :href="'https://alkafeel.net/projects?lang='+$i18n.locale" class="tl">{{ $t("the_projects") }}</a>
        </div>
        <p class="tl">{{ $t("index_projects_full") }}</p>
        <!-- <p class="tx ta">نعرض لكم في هذا القسم المخصص لأنجازات ومشاريع العتبة العباسية المقدسة نعرض لكم في هذا القسم المخصص لأنجازات ومشاريع العتبة العباسية المقدسة</p> -->
        <div class="nav">
            <a class="tl" :href="'https://alkafeel.net/projects?lang='+$i18n.locale">{{ $t("all_projects") }}</a>
            <div class="btns">
                <div class="lbtn2"></div>
                <div class="rbtn2"></div>
            </div>
        </div>
    </div>
    <div class="slider" v-if="projects_skeleton">
        <swiper class="swiper dirr" ref="mySwiper" :options="swiperOptions2">
            <swiper-slide class="swiper-slide" v-for="i in res" :key="i">
                <v-skeleton-loader class="mx-auto" width="300" min-width="300" max-width="300" type="image"></v-skeleton-loader>
                <v-skeleton-loader class="mx-auto" width="300" min-width="300" max-width="300" type="image"></v-skeleton-loader>
                <v-skeleton-loader class="mx-auto" width="300" min-width="300" max-width="300" type="image"></v-skeleton-loader>
            </swiper-slide>
        </swiper>
    </div>
    <div class="slider sld" v-else>
        <swiper class="swiper swp dirr" ref="mySwiper" :options="swiperOptions2">
            <swiper-slide v-for="i in res" :key="i">
                <a class="fill-height" :href="'https://alkafeel.net/projects/view.php?id='+i.id+'&lang='+$i18n.locale">
                    <img  :src="'https://alkafeel.net/projects_new/res/img/main/'+i.iid+'.jpg?'" :alt="i.title" />
                    <!-- <v-img aspect-ratio="1"  :src="'https://alkafeel.net/projects_new/res/img/main/'+i.iid+'.jpg?'"></v-img> -->
                    <div class="p_con tl">{{i.title|subStrArtTitle}}</div>
                </a>
                <!-- <a class="fill-height" :href="'https://alkafeel.net/projects_new/pro?id='+i.id+'&lang='+$i18n.locale">
                    <img  :src="'https://alkafeel.net/projects_new/res/img/main/'+i.iid+'.jpg?'" :alt="i.title" />
                   <div class="p_con tl">{{i.title|subStrArtTitle}}</div>
                </a> -->
            </swiper-slide>
        </swiper>
    </div>
</div>
</template>



<script>


export default {
    name: 'carrousel',
    data() {
        return {
            res:[],
            swiperOptions2: {
                slidesPerView: "auto",
                 spaceBetween:40,
                 loop:true,
                freeMode: true,
                //loop:true,
                navigation: {
                    nextEl: ".rbtn2",
                    prevEl: ".lbtn2",
                },
                keyboard: true,
            },
            projects_skeleton : false,
        }
    },
    
    computed: {
        swiper() {return this.$refs.mySwiper.$swiper}
    },
    mounted() {
    },
    created () {
        this.initialize();
    },
       
    methods:{
        initialize() {
          //  this.loading = true;
            this.$axios.get("projects/home", {
            })
            .then(res => {
                //this.loading = false;
                this.res = res.data.Data;
                // console.log(res.data);
                this.projects_skeleton = false;
            })
            .catch(() => {
                //this.loading = false;
            });
        },
    },
    filters: {
        subStrArtTitle: function (string) {

            if (string != null) {
                return string.substring(0, 24) + '...';
            }

        },
    },
}
</script> 